.contact-page {
  background-color: #f9f9f9;
  padding: 2rem 0;
  border-radius: 10px;
  margin-left: 0px;
  margin-right: 0px;
  margin: auto;
}
.contact-page .row{
  margin-left: 0!important;
    margin-right: 0!important;
}
.contact-page h1 {
  font-size: 2.5rem;
  font-weight: 700;
  color: #333;
}

.contact-page p {
  font-size: 1rem;
  color: #666;
}

.contact-page .contact-btn {
  width: 100%;
  font-size: 1rem;
  font-weight: 600;
  padding: 0.75rem 1rem;
  border-radius: 50px;
  transition: all 0.3s ease;
}

.contact-page .contact-btn:hover {
  transform: scale(1.05);
}

.contact-page .social-icons {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 2rem;
}

.contact-page .social-icon {
  display: inline-block;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #007bff;
  color: #fff;
  font-size: 1.5rem;
  line-height: 50px;
  text-align: center;
  transition: all 0.3s ease;
}

.contact-page .social-icon:hover {
  background: #0056b3;
  transform: scale(1.1);
}
