.footer {
  background-color: #343a40;
  color: #f8f9fa;
  padding: 2rem 0;
  font-family: "Arial", sans-serif;
}

.footer-title {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: #f8f9fa;
}

.footer-section p {
  font-size: 0.9rem;
  line-height: 1.6;
  color: #d6d6d6;
}

.footer-links {
  list-style: none;
  padding: 0;
}

.footer-links li {
  margin-bottom: 0.5rem;
}

.footer-links a {
  color: #f8f9fa;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-links a:hover {
  color: #00bcd4;
}

.footer-social-icons {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

.footer-social-icons a {
  font-size: 1.5rem;
  color: #f8f9fa;
  transition: transform 0.3s ease, color 0.3s ease;
}

.footer-social-icons a:hover {
  color: #00bcd4;
  transform: scale(1.2);
}

.footer-bottom {
  margin-top: 2rem;
  border-top: 1px solid #495057;
  padding-top: 1rem;
  color: #d6d6d6;
  font-size: 0.85rem;
}
