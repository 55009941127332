.about-details-page {
  padding: 80px 20px;
  background-color: #f4f4f4;
}

.about-details-page .details-section {
  margin-bottom: 50px;
  align-items: center;
}

.about-details-page .details-title {
  font-size: 2.5rem;
  font-weight: 700;
  color: #333;
  margin-bottom: 20px;
}

.about-details-page .details-description {
  font-size: 1.2rem;
  color: #666;
  line-height: 1.8;
  margin-bottom: 20px;
}

.about-details-page .details-image {
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}
