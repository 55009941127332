.first-page {
    background:linear-gradient(rgba(47, 47, 47, 0.7),rgba(64, 63, 63, 0.7)), url("./../images/background1.jpg") no-repeat center center/cover;
    height: 100vh;
    width: 100%;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0px;
    margin-right: 0px;
  }
  
  .text-container {
    text-align: center;
  }
  
  .company-name {
    font-size: 3.5rem;
    font-weight: bold;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
    margin-bottom: 20px;
  }
  
  .description {
    font-size: 1.25rem;
    margin-bottom: 30px;
    line-height: 1.6;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
  }
  
  .cta-button {
    padding: 10px 30px;
    font-size: 1.2rem;
    font-weight: bold;
    border-radius: 10px!important;
    transition: transform 0.3s ease, background-color 0.3s ease;
  }
  
  .cta-button:hover {
    transform: scale(1.1);
    background-color: #0056b3;
  }
  